import React from "react"
import { Link } from "gatsby"

import { Image } from "./Image"

type ProjectCardProps = {
  to: string
  name: string
  image: string
}

export const ProjectCard = (props: ProjectCardProps) => (
  <Link to={props.to}>
    <Image src={props.image} />
    <p className="cardTitle">{props.name}</p>
  </Link>
)
