import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ProjectCard } from "../components/ProjectCard"

const projects = [
  {
    name: "Matthews",
    image: "Matthews-cover.png",
    url: "/Matthews",
  },
  {
    name: "Park Hills",
    image: "ParkHills-01.png",
    url: "/ParkHills",
  },
  {
    name: "E10th St",
    image: "E10-cover.png",
    url: "/E10",
  },
  {
    name: "960 Primrose",
    image: "primrose-1.jpg",
    url: "/Primrose",
  },
  {
    name: "503 Lone Fir",
    image: "loneFir-11.jpg",
    url: "/LoneFir",
  },
  {
    name: "69 Wilson Mesa",
    image: "wilsonMesa-2.jpg",
    url: "/WilsonMesa",
  },
  {
    name: "Reconstructing Incarceration",
    image: "incarceration-hero.jpg",
    url: "/Incarceration",
  },
  {
    name: "Pool Concept",
    image: "poolConcept-7.jpg",
    url: "/PoolConcept",
  },
  {
    name: "Retail Concept",
    image: "retailConcept-7.jpg",
    url: "/RetailConcept",
  },
  {
    name: "Models",
    image: "models-thumbnail.jpg",
    url: "/Models",
  },
  {
    name: "Sketches",
    image: "sketch-thumbnail.jpg",
    url: "/Sketches",
  },
]

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <div className="cardContainer">
        {projects.map(project => (
          <ProjectCard
            image={project.image}
            name={project.name}
            to={project.url}
          />
        ))}
      </div>
    </Layout>
  )
}

export default IndexPage
